const config = {
  name: 'Farras Faddila (donbasta)',
  commands: [
    {
      id: 10,
      type: 'ls'
    },
    {
      id: 11,
      type: 'cat'
    },
    {
      id: 12,
      type: 'clear'
    },
    {
      id: 13,
      type: 'help'
    },
    {
      id: 20,
      type: 'message'
    }
  ],
  subDir: [
    {
      id: 14,
      type: 'about.txt'
    },
    {
      id: 15,
      type: 'education.txt'
    },
    {
      id: 16,
      type: 'bin (a.k.a. projects)'
    },
    {
      id: 17,
      type: 'socials.txt'
    },
    {
      id: 18,
      type: 'resume.txt'
    },
    {
      id: 21,
      type: 'hobby.txt'
    },
    {
      id: 22,
      type: 'currently_exploring.txt'
    },

    {
      id: 23,
      type: 'todo_exploring.txt'
    }
  ]
};

export default config;
