const man = {
  help: 'List all of available basic commands',
  man: 'Get the manual of a command. Usage: man <command>',
  ls: 'List all available things inside a directory',
  cat: 'See content of a file. Usage: cat <file>',
  clear: 'Clear terminal',
  message: `Sent message to the administrator.\n
Format:\n
message "<message>"\n
Example:\n
message "good afternoon"\n
Optional Flags:\n
-u "<sender_name>" (e.g.: message "good afternoon" -u "Elon Musk")\n
\n
p.s. don't forget the double quotation marks enclosing the message and the sender_name, it's very important!`
};

export default man;
