import React from 'react';

const Ls = ({line: {type}, subDir}) => {
  return (
    <>
      <p className="prompt"> {type} </p>
      <ul style={{listStyleType: 'none'}}>
        {subDir.map(l => (
          <li key={l.id} className="result">
            {l.type}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Ls;
