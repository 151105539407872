import React, {useState} from 'react';
import man from '../data/man';

const Man = ({line}) => {
  const [state, setState] = useState(line);

  const handleCommand = () => {
    const navigation = state.value.split(' ')[1];
    if (navigation) {
      const lower = navigation.toLowerCase();
      if (lower in man) {
        return <p className="result">{man[lower]}</p>;
      } else {
        return <p className="result">Command not found! 😥</p>;
      }
    }
  };

  return (
    <>
      <p className="prompt"> {line.value} </p>
      {handleCommand()}
    </>
  );
};

export default Man;
