import React, {Component} from 'react';

class NewLine extends Component {
  state = {
    id: this.props.line.id,
    value: this.props.line.value,
    displayInput: this.props.line.displayInput
  };

  focusInput = component => {
    if (component) {
      component.focus();
    }
  };

  render() {
    return (
      <>
        <p className={this.badgeChange()}>
          {this.props.displayInput && (
            <input
              type="text"
              className="here"
              ref={this.focusInput}
              value={this.state.value}
              onChange={this.handleChange}
              onKeyDown={this.handleEnter}
              autoFocus
            />
          )}
          {this.state.value}
        </p>
      </>
    );
  }

  handleChange = event => {
    this.setState({value: event.target.value});
  };

  handleEnter = event => {
    if (event.key === 'Enter') {
      this.props.handleWhatever(event.target.value, this.state.id);
    }
  };

  badgeChange() {
    let badge = 'prompt ';
    badge += this.props.displayInput ? 'output new-output' : '';
    return badge;
  }
}

export default NewLine;
