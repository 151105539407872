import React, {Component} from 'react';
import githubLogo from '../assets/GitHub-Mark-Light-32px.png';
import information from '../data/information';

class Cat extends Component {
  state = {
    type: this.props.line.type,
    value: this.props.line.value
  };
  style = {
    align: 'middle'
  };
  render() {
    return (
      <>
        <p className="prompt"> {this.props.line.value} </p>
        {this.handleCommand()}
      </>
    );
  }

  handleCommand = () => {
    const navigation = this.state.value.split(' ')[1];
    if (navigation) {
      const lower = navigation.toLowerCase();
      if (lower === 'about.txt') {
        return <p className="result">{information.about}</p>;
      } else if (lower === 'education.txt') {
        return (
          <>
            {information.education.map(e => {
              return <p className="result">-{e}</p>;
            })}
          </>
        );
      } else if (
        lower === 'projects' ||
        lower === 'project' ||
        lower === 'bin'
      ) {
        return (
          <>
            {information.projects.map(everyProject => {
              return (
                <p className="result">
                  {everyProject.projectName}
                  <a href={everyProject.liveDemo} target="_blank">
                    Live Link
                  </a>
                  <a href={everyProject.linkToGithub} target="_blank">
                    <img src={githubLogo} alt="GithubLink to Code" />
                  </a>
                  {everyProject.githubVisibility}
                </p>
              );
            })}
          </>
        );
      } else if (lower === 'socials.txt') {
        return (
          <>
            {information.social.map(everySocial => {
              return (
                <p className="result">
                  <a href={everySocial.link} target="_blank">
                    {everySocial.platform}
                  </a>
                  <a href={everySocial.link} target="_blank">
                    <img src={everySocial.logo} alt="Social Medial Logo" />
                  </a>
                </p>
              );
            })}
          </>
        );
      } else if (lower === 'resume.txt') {
        return (
          <p className="result">
            <a
              rel="noopener noreferrer"
              href={information.resume}
              target="_blank"
            >
              View Resume
            </a>
          </p>
        );
      } else if (lower === 'hobby.txt') {
        return (
          <>
            {information.hobby.map(h => {
              return <p className="result">-{h}</p>;
            })}
          </>
        );
      } else if (lower === 'currently_exploring.txt') {
        return (
          <>
            {information.currently_exploring.map(c => {
              return <p className="result">-{c}</p>;
            })}
          </>
        );
      } else if (lower === 'todo_exploring.txt') {
        return (
          <>
            {information.todo_exploring.map(t => {
              return <p className="result">-{t}</p>;
            })}
          </>
        );
      } else {
        return <p className="result">File {navigation} not found... 😥</p>;
      }
    } else {
      return <p className="result">Invalid input 😥</p>;
    }
  };
}

export default Cat;
