import React, {useEffect, useState} from 'react';

const Message = ({line}) => {
  const [sentStatus, setSentStatus] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(
    'Message not sent. Please try again...'
  );

  const splitLineToTokens = value => {
    let msg = '',
      sender = '';
    const t = value.split('"');
    if (t.length === 3) {
      msg = t[1];
    } else if (t.length === 5) {
      if (t[2].trim() === '-u') {
        msg = t[1];
        sender = t[3];
      } else {
        setInvalidMessage(`invalid option ${t[2].trim()}`);
        return null;
      }
    } else {
      setInvalidMessage(`invalid message, check the quotation mark!`);
      return null;
    }
    return {
      msg: msg,
      sender: sender
    };
  };

  useEffect(() => {
    const body = splitLineToTokens(line.value.trim());
    if (body === null) {
      setSentStatus(false);
      return;
    }
    const url =
      'https://script.google.com/macros/s/AKfycbxDZVnApGT58Xqxh2trpmFYh738VjSPLsvvRZlOl4VdUTiQgD_MwMoaTy3Dmfbkr3I/exec';
    const config = {
      body: JSON.stringify(body),
      method: 'post',
      headers: {'Content-type': 'text/plain;charset=utf-8'}
    };
    fetch(url, config)
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.status === 'SUCCESS') {
          setSentStatus(true);
        } else {
          setSentStatus(false);
        }
      })
      .catch(_ => {
        setSentStatus(false);
      });
  }, []);
  return (
    <>
      <p className="prompt"> {line.value} </p>
      {sentStatus === null && <p className="result">Sending...</p>}
      {sentStatus === true && <p className="result">Message Sent!</p>}
      {sentStatus === false && <p className="result">{invalidMessage}</p>}
    </>
  );
};

export default Message;
