import React, {Component} from 'react';
import NewLine from './newLine';
import Ls from './ls';
import Cat from './cat';
// import asciiHeader from '../assets/asciiHeader';
import Man from './man';
import Message from './message';
import config from '../data/config';

class Page extends Component {
  state = {
    numberOfLine: 0,
    displayEverything: [{value: '', id: 0, displayInput: true, type: 'line'}]
  };
  render() {
    return (
      <div className="container">
        <div className="terminal">
          {/* <p className="prompt-header">{asciiHeader}</p> */}
          <div className="header-box">
            <p className="text">Hi!</p>
            <p className="text">
              Welcome to <strong>donbasta.dev</strong>, website of {config.name}
              .{' '}
            </p>
            <p className="text">
              Call me the <em>administrator</em>.
            </p>
            <p className="text">
              For list of basic commands, type <em>help</em>
            </p>
            <p className="text">
              For usage of specific command, type <em>man</em> followed by the
              command (e.g. <em>man help</em>)
            </p>
          </div>

          {this.state.displayEverything.map(l => {
            if (l.type === 'line') {
              return (
                <NewLine
                  key={l.id}
                  handleWhatever={this.handleWhatever}
                  line={l}
                  displayInput={l.displayInput}
                ></NewLine>
              );
            } else if (l.type === 'ls') {
              return <Ls key={l.id} line={l} subDir={config.subDir}></Ls>;
            } else if (l.type === 'help') {
              return <Ls key={l.id} line={l} subDir={config.commands}></Ls>;
            } else if (l.type === 'cat') {
              return <Cat key={l.id} line={l}></Cat>;
            } else if (l.type === 'man') {
              return <Man key={l.id} line={l}></Man>;
            } else if (l.type === 'message') {
              return <Message key={l.id} line={l}></Message>;
            }
            return (
              <>
                <p className="prompt"> {l.value} </p>
                <p className="result">Command {l.type} not found!</p>
              </>
            );
          })}
        </div>
      </div>
    );
  }

  handleWhatever = (string_value, Tid) => {
    let res = string_value.split(' ');

    if (res[0] === 'clear') {
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          {value: '', id: 0, displayInput: true, type: 'line'}
        ]
      });
    } else {
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    }
  };

  handleClick = event => {
    const num = this.state.displayEverything.length + 1;
    this.setState({
      numberOfLine: this.state.numberOfLine + 1,
      displayEverything: [
        ...this.state.displayEverything,
        {value: '', id: num, displayInput: true, type: 'line'}
      ]
    });
  };
}

export default Page;
