import githubLogo from '../assets/GitHub-Mark-Light-32px.png';
import linkedInLogo from '../assets/1492718749-linkedin_83603.png';

const information = {
  about:
    'I am an undergraduate student, a software developer, a math and competitive programming enthusiast, and a self learner.',
  education: [
    `Bandung Institute of Technology (2018 - now), majoring in Computer Science`,
    `Kharisma Bangsa High School (2012 - 2018)`,
    `Pondok Ranggon 01 State Elementary School (2006 - 2012)`
  ],
  projects: [
    {
      projectName: 'Link Shortener',
      liveDemo: 'https://pendekin.hmif.tech',
      linkToGithub: 'https://github.com/hmif-itb/pendekin',
      githubVisibility: ''
    },
    {
      projectName: 'Latex Math Blog',
      liveDemo: 'https://mathy.donbasta.dev',
      linkToGithub: 'https://github.com/donbasta/mathy',
      githubVisibility: 'private repository'
    },
    {
      projectName: 'Simple Crypto App',
      liveDemo: 'https://donmasha.com/kripto/',
      linkToGithub: 'https://github.com/donbasta/kripto1',
      githubVisibility: ''
    },
    {
      projectName: 'Halma with AI-based Bot',
      liveDemo: 'https://halma-euy.netlify.app/',
      linkToGithub: 'https://github.com/donbasta/halma-AI',
      githubVisibility: ''
    }
  ],
  social: [
    {
      platform: 'Github',
      link: 'https://github.com/donbasta',
      logo: githubLogo
    },
    {
      platform: 'LinkedIn',
      link: 'https://linkedin.com/in/farrasfaddila/',
      logo: linkedInLogo
    }
  ],
  resume:
    'https://docs.google.com/document/d/1a7eSDb08KEPrC5z5jsbw4WNvd-yBesYY45Fpnpprkc0/',
  hobby: [
    'Playing classical guitar',
    'Playing basketball',
    'Surfing AoPS or Codeforces',
    'Reading wholesome/tragic/bittersweet manga',
    'Scrolling twitter'
  ],
  currently_exploring: ['rust', 'golang', 'nextjs', 'lambda calculus'],
  todo_exploring: [
    'turing machine',
    'haskell',
    'transformers model',
    'moving point'
  ]
};

export default information;
